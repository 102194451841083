import React from "react"
import {graphql} from "gatsby";
import Layout from "../../components/templates/Layout";
import Page from "../../components/molecules/NodeTypes/page";
import {getGreeting, currentLanguageByPath} from "../../helpers/MainHelper";

const title = getGreeting(currentLanguageByPath())

const IndexEnPage = ({data}) => (
    <Layout
        lang={'en'}
        class={'home'}
        activeMenu={'home'}
        activeNid={1}
    >
        <Page nodeContent={data.drupal.page} title={title} />
    </Layout>
);
export default IndexEnPage;

export const query = graphql`
query indexEnPage {
    drupal {
        page:entityById(id: "1", langcode: EN, entityType: NODE) {
            ... on Drupal_NodePage {
                nid
                title
                body 
                fieldOpening 
                internalId: nid
                path {
                    alias
                }
                metatagNormalized {
                    tag
                }
            }
        }
    }
}
`;

